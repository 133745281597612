import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private alertController: AlertController,
    private translate: TranslateService,
    private toastController: ToastController,
    private router: Router,
  ) { }

  public areYousure(titleT, bodyT, anyFunction, bdDismiss = true, onlyButtonT = '') {
    let alertWords = [titleT, bodyT,'GENERAL.YES', 'GENERAL.NO', onlyButtonT];
    this.translate.get(alertWords).subscribe(traslations => {
      async function presentAlert(vm){
        const alert = await vm.alertController.create({
          header: traslations[titleT],
          message: traslations[bodyT],
          cssClass: 'contactless-texts',
          animated: true,
          backdropDismiss: bdDismiss,
          buttons: onlyButtonT !== '' ? [
            {
              text: traslations[onlyButtonT],
              handler: () => {
                anyFunction()
              }
            }
          ] : 
          [
            {
              text: traslations['GENERAL.NO'],
              role: 'cancel',
              cssClass: 'contactless',
              handler: (blah) => {}
            }, {
              text: traslations['GENERAL.YES'],
              handler: () => {
                anyFunction()
              }
            }
          ]
        });

        await alert.present();
      }
      presentAlert(this);
    });
  }

  presentToast(title: string, description: string, cssClass: string, duration: number = 3000, position: string = 'bottom') {
    this.translate.get([title, description]).subscribe(traslations => { 
      async function presentToastAux(vm) {
        const toast = await vm.toastController.create({
          header: traslations[title],
          message: traslations[description],
          duration: duration,
          cssClass: cssClass,
          position: position
        });
        await toast.present();
      } 
      presentToastAux(this);
    })
  }

  public validateOrBack(callbackFn, header: string, description: string, wordsParams = {}) {
    let alertWords = ['MYSTAY.VALIDATE', 'BACK', header, description]
    this.translate.get(alertWords, wordsParams).subscribe(traslations => {
      async function presentAlert(vm){
        const alert = await vm.alertController.create({
          header: traslations[header],
          message: traslations[description],
          cssClass: 'contactless-texts',
          animated: true,
          backdropDismiss: true,
          buttons:  
          [
            {
              text: traslations['BACK'],
              role: 'cancel',
              cssClass: 'contactless',
              handler: () => {}
            }, {
              text: traslations['MYSTAY.VALIDATE'],
              handler: () => {
                callbackFn()
              }
            }
          ]
        });

        await alert.present();
      }
      presentAlert(this);
    });
  }

  public billClosed() {
    let alertWords = ['MYSTAY.VALIDATE_WITH_ANOTHER_BILL', 'MYSTAY.CALL_WAITER', 'ERROR.BILL_IS_CLOSED_HEADER', 'ERROR.BILL_IS_CLOSED_DESCRIPTION']
    this.translate.get(alertWords).subscribe(traslations => {
      async function presentAlert(vm){
        const alert = await vm.alertController.create({
          header: traslations['ERROR.BILL_IS_CLOSED_HEADER'],
          message: traslations['ERROR.BILL_IS_CLOSED_DESCRIPTION'],
          cssClass: 'contactless-texts',
          animated: true,
          backdropDismiss: true,
          buttons:
              [
                {
                  text: traslations['MYSTAY.CALL_WAITER'],
                  handler: () => {
                    vm.router.navigate(['/restaurants/my-stay/home']);
                  }
                }, {
                text: traslations['MYSTAY.VALIDATE_WITH_ANOTHER_BILL'],
                handler: () => {
                  vm.router.navigate(['/restaurants/my-stay/associate'])
                }
              }
              ]
        });

        await alert.present();
      }

      presentAlert(this);
    });
  }

  public warning(header: string, description: string) {
    const alertWords = ['BACK', header, description];
    this.translate.get(alertWords).subscribe(traslations => {
      async function presentAlert(vm) {
        const alert = await vm.alertController.create({
          header: traslations[header],
          message: traslations[description],
          cssClass: 'contactless-texts',
          animated: true,
          backdropDismiss: true,
          buttons:
              [
                {
                  text: traslations.BACK,
                  role: 'cancel',
                  cssClass: 'contactless',
                  handler: () => {
                  }
                }
              ]
        });

        await alert.present();
      }
      presentAlert(this);
    });
  }

  public notification(titleT, bodyT, icon = null, customProvider = false, wordsParams = {}, bdDismiss = true, classText: string = null) {
    let alertWords = [titleT, bodyT, 'GENERAL.OK'];
    let messageIcon = icon ? `<ion-icon class="modal-icon-container" color="primary" name='` + icon + `'></ion-icon>` : '';
    let divModal = classText ? `<div class='` + classText + `'>` : `<div>`;
    this.translate.get(alertWords, wordsParams).subscribe(translations => {
      async function presentAlert(vm){
        const alert = await vm.alertController.create({
          header: translations[titleT],
          cssClass : customProvider ? 'custom-provider' : 'contactless-texts',
          message:
            divModal +
              messageIcon +
              translations[bodyT] +
            `</div>`,
          animated: true,
          backdropDismiss: bdDismiss,
          buttons: [
            {
              text: translations['GENERAL.OK'],
              role: 'cancel',
              cssClass: 'contactless',
              handler: (blah) => {}
            }
          ]
        });

        await alert.present();
      }
      presentAlert(this);
    });
  }

}
