import { FiscalEntity } from "../fiscal-entity/fiscal-entity";
import { PaymentType } from "../payment-type/payment-type";

export class Bill {
    public id: number;
    public is_closed: boolean;
    public owner: boolean;
    public tax_regime: FiscalEntity;
    public digital_tax_receipt: FiscalEntity;
    public social_reason: string;
    public tip: number;
    public tax_code: string;
    public postal_code: string;
    public email: string
    public payment_type: PaymentType;
    public signature_image_url: string;
    public client_name: string;
    public is_invoice_required: boolean;

    
	constructor() {}

    static init(data, payment_types: []) {
         let instance = new Bill();
         instance.id = data.id;
         instance.is_closed = data.is_closed;
         instance.owner = data.owner;
         instance.tax_regime = data.tax_regime
         instance.digital_tax_receipt = data.digital_tax_receipt;
         instance.tip = data.tip;
         instance.tax_code = data.tax_code;
         instance.postal_code = data.postal_code;
         instance.email = data.email;
         instance.social_reason = data.social_reason;
         instance.signature_image_url = data.signature_image_url;
         instance.client_name = data.client_name;
         instance.is_invoice_required = data.is_invoice_required;

         const payment_type_data: any = payment_types.find((given_payment_type: any) => given_payment_type.id == data.payment_type_id);
         instance.payment_type = PaymentType.init(payment_type_data.id, payment_type_data.slug);

        return instance;
    }
}