import { Injectable } from "@angular/core";
import { InitialSetup } from "../models";
import { InitialSetupService } from "../services";

@Injectable()
export class InitialSetupProvider {
  private initialSetup: InitialSetup = null;

  constructor(
    private initialSetupService: InitialSetupService
  ) { }

  public getInitialSetup(): InitialSetup {
    return this.initialSetup;
  }

  load() {
    return new Promise((resolve, reject) => {
      const vm = this;
      vm.initialSetupService.setInitialSetup().then(res => {
        vm.initialSetupService.initialSetup.subscribe(initialSetup => {
          this.initialSetup = initialSetup.data;
          resolve(true);
        });
      });
    });
  }
}