import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class AmenitiesCatalogsService extends GeneralService<any>{

  public amenitiesCatalogs: any;

  constructor(protected http: HttpClient) {
    super(http, 'amenity-catalogs');
  }

  public getById(id: number) {
    return this.amenitiesCatalogs.data.find((amenityCatalog) => amenityCatalog.id === id);
  }

  getAll() {
    let data;
    if (this.amenitiesCatalogs) {
      data = this.amenitiesCatalogs;
    } else {
      data = this.get();
      data.subscribe(response => {
        this.amenitiesCatalogs = response;
      });
    }
    return data;
  }
}
