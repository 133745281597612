import { Translations } from '../translations/translations';

export class PaymentType {  
  public id: number;
  public slug: string;
  public translations: Translations;
  public created_at: string;
  public updated_at: string;

  static init(id, slug) {
    let instance = new PaymentType();
    instance.id = id;
    instance.slug = slug;

    return instance;
  }
}
