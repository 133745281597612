import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { ConstantsService } from '../services';
import { AcronymService } from '../services/acronym/acronym.service';
import { StorageService } from '../services/storage/storage.service';
import {catchError, retry} from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ConnectToBackend implements HttpInterceptor {

  constructor(
    private router: Router,
    private acronym: AcronymService,
    private storage: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const acronym = this.acronym.parse();
    if (!acronym){
      this.router.navigate(['/errors/property-not-found']);
    }
    let cloneReq = request.clone({
      params: request.params.set(
        'acronym', acronym
      )
    });

    const token: string = this.storage.get(ConstantsService.LOCALSTORAGE_TOKEN_INDEX);
    if (token) {
      cloneReq = cloneReq.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(cloneReq).pipe(
      catchError(err => this.handleAuthError(err))
    );
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 0) {
      this.router.navigate(['/errors/something-went-wrong']);
    }
    if (err.status === 401) {
      this.storage.remove(ConstantsService.LOCALSTORAGE_TOKEN_INDEX);
      this.router.navigateByUrl('/landing/search');
    }
    return throwError(err);
  }
}