import { Reservation } from 'src/app/models/reservation/reservation';

export class MyReservations {
  public actived: Reservation[];
  public inactived: Reservation[];

  static instanceFromJson(data: any): MyReservations{
    let instance = new MyReservations();
    instance.actived = data.actived.map(reservation => Reservation.instanceFromJson(reservation));
    instance.inactived = data.inactived.map(reservation => Reservation.instanceFromJson(reservation));

    return instance;
  }
}
