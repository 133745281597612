import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from 'src/app/models';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService extends GeneralService<any>{

  constructor(
    protected http: HttpClient,
  ) {
    super(http, 'menu-items');
  }

  public unique(): Observable<ApiResponse<any>> {
    return new Observable(observer => {
      super.unique().subscribe(apiResponse => {
        const home = [{
          type: 'home',
          icon: './assets/icon/home.svg',
          translations: { en: { title: 'Home' }, es: { title: 'Inicio' }, fr: { title: 'Début' }, pt: { title: 'Começar' } }
        }];

        apiResponse.data.menu_items = home.concat(apiResponse.data.menu_items);

        observer.next(apiResponse);
        observer.complete();
      });
    });
  }





}
