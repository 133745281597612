import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general/general.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LexiService extends GeneralService<any>{

  private lexi;

  constructor(protected http: HttpClient){
    super(http, '');
  }

  public redirToLexi(url: string): Observable<any> {
    var data = this.getWithExtraUrlAndParams(url, '');
    data.subscribe( response => {
      this.lexi = response.data;
    });
    return data;
  }

}
