/**
 * ARCHIVO DE CONFIGURACION DE AMBIENTE LOCAL PARA PRUEBAS
 * Para correcto uso de la aplicacion crear un archivo env.ts que se utilizará para la comunicacion con la api local.
 * El cual debe tener el contenido no comentado de este archivo
 * 
 * En LOCAL_API_PATH:
 * Cambiar el texto TENANT_NAME por el nombre del tenant en cuestion.
 * Cambiar el texto TENANT_MAIN por el nombre del multitenant.
 */
 export const LOCAL_API_PATH = 'http://TENANT_NAME.TENANT_MAIN.localhost.com:8000';


/**
 * Se debe setear el `DEFAULT_ANALYTICS_CODE` para el uso de Google Analytics en caso de no tenerlo seteado en la propiedad.
 * 
*/
 export const DEFAULT_ANALYTICS_CODE = 'UA-167856943-9';
