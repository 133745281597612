import { Translations } from '../translations/translations';

export class ExternalLink {
  public id: number;
  public property_id: number;
  public open_in_new_window: number;
  public image_name: string;
  public image_url: string;
  public translations: Translations;
  public created_at: string;
  public updated_at: string;

  static instanceFromJson(data: any): ExternalLink {
    let instance = new ExternalLink();
    instance.id = data.id;
    instance.property_id = data.property_id;
    instance.open_in_new_window = data.open_in_new_window;
    instance.image_name = data.image_name;
    instance.image_url = data.image_url;
    instance.translations = Translations.instanceFromJson(data.translations);
    instance.created_at = data.created_at;
    instance.updated_at = data.updated_at;

    return instance;
  }

  findAvailableUrl() {
    let url = '';
    Object.entries(this.translations).forEach(([k, v]) => {
      if (null !== v.url){
        url = v.url;
      }
    });
    return url;
  }
}
