import { Translations } from '../translations/translations';

export class HotelInfo {
    public id: number;
    public hotel_info_category_id: number;
    public property_id: number;
    public place_id: number;
    public image_name: string;
    public image_url: string;
    public phone: string;
    public translations: Translations;
    public created_at: string;
    public updated_at: string;

    //Translations
    public name: string;

    public static basicInstance(id: number, image_url: string, name: string) {
        let instance = new this();
        instance.id = id;
        instance.image_url = image_url;
        instance.name = name;
        
        return instance;
    }
}
