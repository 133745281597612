import { Translations } from '../translations/translations'

export class Catalog {
    public id: number;
    public use_pdf: number;
    public pdf_name: string;
    public pdf_url: string;
    public property_id: number;
    public restaurant_id: number;
    public image_name: string;
    public image_url: string;
    public position: number;
    public translations: Translations;
    public updated_at: string;
    public created_at: string;
    
    //Translations
    public name: string;

    public static basicInstance(image_url: string, name: string) {
        let instance = new this();
        instance.image_url = image_url;
        instance.name = name;
        
        return instance;
    }

    public static majorInstance(id: number, name: string, image_url: string) {
        let instance = new this();

        instance.id = id;
        instance.name = name;
        instance.image_url = image_url;
        
        return instance;
    }
}