import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { GeneralService } from '../general/general.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogsService extends GeneralService<any>{

  public allCatalogs: any;
  public catalogs = { data: {}};
  constructor(protected http: HttpClient){
    super(http, 'catalogs');
  }

  public getByRestaurantName(name: string): Observable<any> {
    let data;
    if (this.catalogs && this.catalogs.data[name]){
      data = { data: this.catalogs.data[name]};
    }else{
      const params = new HttpParams().set('name', name);
      data = this.getWithExtraUrlAndParams(`/by-restaurant-name/${name}`, params);
      data.subscribe( response => {
        this.catalogs.data[name] = response.data;
      });
    }
    return data;
  }

  public getByRestaurantSlug(slug: string): Observable<any> {
    let data;
    if (this.catalogs && this.catalogs.data[slug]){
      data = { data: this.catalogs.data[slug]};
    }else{
      data = this.getWithExtraUrlAndParams(`/by-restaurant-slug/${slug}`, '');
      data.subscribe( response => {
        this.catalogs.data[slug] = response.data;
      });
    }
    return data;
  }

  public getAll() {
    let data;
    if (this.allCatalogs){
      data = this.allCatalogs;
    }else{
      data = this.get();
      data.subscribe( response => {
        this.allCatalogs = response;
      });
    }
    return data;
  }

  public findByRestaurantId(restaurantId: number) {
    return this.allCatalogs.data.filter( (catalogs) => catalogs.restaurant_id === restaurantId );
  }
}
