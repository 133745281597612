import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringService {

  constructor() { }

  parseToUrl(name): string {
    return name.replace(/\s/g, '-');
  }

  parseToData(name): string {
    return name.replace(/\-/g, ' ');
  }
}
