import { CategoryDisplayType } from "../category-display-type/category-display-type";
import { Translations } from "../translations/translations";
import { Widget } from "../widget/widget";

export class HomeCategory {
  public id: number;
  public property_id: number;
  public category_display_type_id: number;
  public alignment: string;
  public position: number;
  public show_title: boolean;
  public translations: Translations;
  public category_display_type: CategoryDisplayType;
  public widgets: Widget[];

  static instanceFromJson(data: any): HomeCategory {
    let instance = new HomeCategory();
    instance.id = data.id;
    instance.property_id = data.property_id;
    instance.category_display_type_id = data.category_display_type_id;
    instance.position = data.position;
    instance.show_title = data.show_title;
    instance.translations = Translations.instanceFromJson(data.translations);
    instance.category_display_type = CategoryDisplayType.instanceFromJson(data.category_display_type);
    instance.widgets = data.widgets;
    instance.alignment = data.alignment;

    return instance;
  }
}
