export class ProviderType {
  public id: number;
  public type: string;
  public created_at: string;
  public updated_at: string;

  static instanceFromJson(data: any): ProviderType {
    let instance = new ProviderType();
    instance.id = data.id;
    instance.type = data.type;
    instance.created_at = data.created_at;
    instance.updated_at = data.updated_at;

    return instance;
	}
}
