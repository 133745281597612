import { Translations } from '../translations/translations';

export class Provider {
  public id: number;
  public name: string;
  public image_name: string;
  public property_id: number;
  public created_at: string;
  public updated_at: string;
  public start_time: string;
  public end_time: string;
  public show_title: boolean;
  public avg_delivery_time: string;
  public translations: Translations;

  static instanceFromJson(data: any): Provider {
    let instance = new Provider();
    instance.id = data.id;
    instance.name = data.name;
    instance.image_name = data.image_name;
    instance.property_id = data.property_id;
    instance.created_at = data.created_at;
    instance.updated_at = data.updated_at;
    instance.start_time = data.start_time;
    instance.end_time = data.end_time;
    instance.show_title = data.show_title;
    instance.avg_delivery_time = data.avg_delivery_time;
    instance.translations = Translations.instanceFromJson(data.translations);

    return instance;
	}
}