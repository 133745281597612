import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReservationResponse, AutoRegistrationTokenResponse } from './landing.interface';
import { GeneralService } from '../general/general.service';
import { ConstantsService } from '../constants/constants.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class LandingService extends GeneralService<any> {

  private eventsSubject = new Subject<string>();

  constructor(protected http: HttpClient, 
              protected storage: StorageService){
    super(http, 'reservations');
  }

  dispatchEvent(event: string) {
    this.eventsSubject.next(event);
  }

  events() {
    return this.eventsSubject;
  }

  find(authenticationFields: string, roomNumber: string, lastName: string, phoneNumber: string, eMail: string): Observable<ReservationResponse> {
    return this.http.post<ReservationResponse>(`${this.getApiPath()}/reservations/search`, {
      authentication_fields: authenticationFields,
      room_number: roomNumber,
      last_name: lastName,
      phone_number: phoneNumber,
      email: eMail
    }).pipe(map((response: any) =>
      ({success: response.success, token: response.data })
    ));
  }

  create(lastName: string,
         roomNumber: string,
         eMail: string,
         eMailConfirmation: string,
         checkIn: string,
         checkOut: string): Observable<ReservationResponse> {
    return this.http.post<ReservationResponse>(`${this.getApiPath()}/reservations/create`, {
      room_number: roomNumber,
      last_name: lastName,
      email: eMail,
      email_conf: eMailConfirmation,
      checkin: checkIn,
      checkout: checkOut,
      language_code: this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX)
    }).pipe(map((response: any) =>
      ({success: response.success, token: response.data })
    ));
  }

  lookupAutoRegistrationToken(autoRegistrationToken: string): Observable<AutoRegistrationTokenResponse> {
    return this.http.get<AutoRegistrationTokenResponse>(`${this.getApiPath()}/auto-registration-token/${autoRegistrationToken}`)
              .pipe(map((response: any) => response.data));
  }

  autoRegistrationAutomatic(autoRegistrationToken: string, eMail: string, eMailConfirmation: string): Observable<ReservationResponse> {
    return this.http.post<ReservationResponse>(`${this.getApiPath()}/exchange-auto-registration-token-automatic`,{
        email: eMail,
        email_conf: eMailConfirmation,
        token: autoRegistrationToken
    }).pipe(map((response: any) =>
        ({success: response.success, token: response.data })
    ));
  }

  autoRegistrationManual(autoRegistrationToken: string, 
                         lastName: string,
                         eMail: string,
                         eMailConfirmation: string,
                         checkIn: string,
                         checkOut: string): Observable<ReservationResponse> {
    return this.http.post<ReservationResponse>(`${this.getApiPath()}/exchange-auto-registration-token-manual`,{
        last_name: lastName,
        email: eMail,
        email_conf: eMailConfirmation,
        checkin: checkIn,
        checkout: checkOut,
        token: autoRegistrationToken
    }).pipe(map((response: any) =>
        ({success: response.success, token: response.data })
    ));
  }

  autoRegistrationTransparent(autoRegistrationToken: string) {
    return this.http.post<ReservationResponse>(`${this.getApiPath()}/exchange-auto-registration-token-transparent`, {
        token: autoRegistrationToken
    }).pipe(map((response: any) =>
        ({success: response.success, token: response.data })
    ));
  }
}
