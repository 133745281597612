import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InputsValidationsHelper {

    constructor(){}

    public validateInput(form: any, event: any, field: string, pattern?: any, min?: number, max?: number): void {
        const input = event.target.value;

        if (min != null && input < min) {
            form.get(field).setValue(min);
            event.target.value = min;
        } else if (max != null && input > max) {
            form.get(field).setValue(max);
            event.target.value = max;
        } else if (pattern && !pattern.regex.test(input)) {
            const value = pattern.parseFn(input);
            form.get(field).setValue(value);
            event.target.value = value;
        }
    }
}
