export const RING_FILE = 'assets/softphone/sounds/ringing.mp3';
export const BUSY_FILE = 'assets/softphone/sounds/busy.mp3';
export const PAD_FILES = [
  'assets/softphone/sounds/dtmf/0.wav',
  'assets/softphone/sounds/dtmf/1.wav',
  'assets/softphone/sounds/dtmf/2.wav',
  'assets/softphone/sounds/dtmf/3.wav',
  'assets/softphone/sounds/dtmf/4.wav',
  'assets/softphone/sounds/dtmf/5.wav',
  'assets/softphone/sounds/dtmf/6.wav',
  'assets/softphone/sounds/dtmf/7.wav',
  'assets/softphone/sounds/dtmf/8.wav',
  'assets/softphone/sounds/dtmf/9.wav',
  'assets/softphone/sounds/dtmf/s.wav',
  'assets/softphone/sounds/dtmf/h.wav',
];

export const SF_ST_WAITING_FOR_USER = 'waiting';
export const SF_ST_DIALING = 'dialing';

export const PH_ST_CONNECTED = 'connected';
export const PH_ST_DISCONNECTED = 'disconnected';
export const PH_ST_REGISTRATIONFAILED = 'registrationFailed';
export const PH_ST_UNREGISTERED = 'unregistered';
export const PH_ST_REGISTERED = 'registered';

export const SE_ST_PROGRESS = 'progress';
export const SE_ST_ACCEPTED = 'accepted';
export const SE_ST_CANCELED = 'canceled';
export const SE_ST_TERMINATED = 'terminated';
export const SE_ST_FAILED = 'failed';
export const SE_ST_REJECTED = 'rejected';
export const SE_ST_BYE = 'bye';
export const SE_ST_DTMF = 'dtmf';

export const T_ST_ERROR = 'transportError';