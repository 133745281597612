import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { GeneralService } from '../general/general.service';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { Restaurant } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class RestaurantsService extends GeneralService<any>{

  private restaurants: any;

  constructor(
    protected http: HttpClient,
  ){
    super(http, 'restaurants');
  }
  
  public getByRestaurantId(restaurant_id: string): Restaurant {
    return this.restaurants.data.find( (restaurant) => restaurant.id === restaurant_id );
  }

  public getByName(name: string) {
    return this.restaurants.data.find( (restaurant) => restaurant.name === name );
  }

  public getBySlug(slug: string) {
    return this.restaurants.data.find( (restaurant) => restaurant.slug === slug );
  }

  public getTemplateBySlug(slug: string) {
    try {
      return this.restaurants.data.find( (restaurant) => restaurant.slug === slug )?.template;
    } catch (e) {
      console.log(e);
    }
  }

  public getCustomActionsBySlug(slug: string) {
    return this.restaurants.data.find( (restaurant) => restaurant.slug === slug ).custom_actions;
  }

  getAll() {
    let data;
    if (this.restaurants){
      data = this.restaurants;
    }else{
      data = this.get();
      data.subscribe( response => {
        this.restaurants = response;
      });
    }
    return data;
  }

  getWithOrdersEnabled() {
    return this.restaurants.data.filter(restaurant => restaurant.allow_orders && restaurant.allow_order_to_diners);
  }
}
