import { Injectable } from '@angular/core';
import {GeneralService} from '../general/general.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RsCatalogsService extends GeneralService<any>{

  public catalogs: any;

  constructor(protected http: HttpClient){
    super(http, 'rs-catalogs/by-room-service');
  }

  public getByRoomServiceId(id) {
    return this.catalogs.data;
  }

  getByCatalogId(id: any) {
    return this.catalogs.data.find( (catalog) => catalog.id === id );
  }

  public getAll() {
    let data;
    if (this.catalogs){
      data = this.catalogs;
    }else{
      data = this.get();
      data.subscribe( response => {
        this.catalogs = response;
      });
    }
    return data;
  }
}
