import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityCategory, ApiResponse } from 'src/app/models';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesByCategoryService extends GeneralService<any> {
  public categories: any;  //Coleccion de categories con las actividades dentro

  constructor(protected http: HttpClient) {
    super(http, 'activities-by-category');
  }

  getCategories(): Observable<ApiResponse<ActivityCategory[]>> {
    let data;
    if (this.categories) {
      let vm = this;
      data = new Observable(observer => {
        observer.next(vm.categories);
        observer.complete();
      });

    } else {
      data = this.get();
      data.subscribe(response => {
        this.categories = response;
      });
    }
    return data;
  }
}