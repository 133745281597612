import { Order } from '../order/order';
import { Translations } from '../translations/translations';

export class FiscalEntity {
    public code: string;
    public translations: Translations;
    
	constructor() {}

    static init(code, translations) {
        let instance = new FiscalEntity();
        instance.code = code;
        instance.translations = Translations.instanceFromJson(translations);

        return instance;
    }
}