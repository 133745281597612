import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService extends GeneralService<any>{

  private paymentTypes: any;
  private currentProviderType: string = null;
  private currentProviderId: number = null;

  constructor(
    protected http: HttpClient,
  ){
    super(http, 'payment-types');
  }

  getAll(providerType: string, providerId: number) {
    let data;
    this.endpoint = 'payment-types?provider-type='+ providerType +'&provider_id='+ providerId;

    if (this.paymentTypes &&
        providerType == this.currentProviderType &&
        providerId == this.currentProviderId){ 
      data = this.paymentTypes;
    } else {
      this.currentProviderType = providerType;
      this.currentProviderId = providerId;
      data = this.get();
      data.subscribe( response => {
        this.paymentTypes = response;
      });
    }
    return data;
  }
}
