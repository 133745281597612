import { Injectable } from "@angular/core";
import { MenuItemsService } from "../services";

@Injectable()
export class MenuItemsProvider {
  private menuItems = null;

  constructor(
    private MenuItemsService: MenuItemsService
  ) { }

  public getMenuItems() {
    return this.menuItems;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.MenuItemsService.unique().subscribe(response => {
        this.menuItems = response.data.menu_items;
        resolve(true);
      });
    });
  }
}