import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import {map, retry, shareReplay, retryWhen, mergeMap, take} from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { LOCAL_API_PATH } from 'src/env';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export abstract class GeneralService<T> {
  apiPath: string;
  privateApiPath: string;
  headers: HttpHeaders;

  constructor(
    protected http: HttpClient,
    protected endpoint: string
  ) {
    const UrlWithoutRoute = this.getUrlWithoutRoute();

    this.apiPath = UrlWithoutRoute + '/api/public';
    this.privateApiPath = UrlWithoutRoute + '/api/devices';

    this.headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });
  }

  protected getUrlWithoutRoute() {
    let url: string;
    if (environment.production) {
      const tenant = location.host.split('.', 1)[0];
      const api_domain = 'api.cloud1-contactless.com';
      url = 'https:' + '//' + tenant + '.' + api_domain;

    } else {
      url = LOCAL_API_PATH;
    }

    return url;
  }

  public getApiPath() {
    return this.apiPath;
  }

  public getPrivateApiPath() {
    return this.privateApiPath;
  }

  public unique(): Observable<ApiResponse<T>> {
    const vm = this;
    return this.http
      .get<ApiResponse<T>>(`${this.getApiPath()}/${this.endpoint}`)
      .pipe(
        retry(1),
        map((data: ApiResponse<T>) => {
          return vm.buildOneResponse(data);
        }),
        shareReplay()
      );
  }

  public getById(id: number): Observable<ApiResponse<T>> {
    const vm = this;
    return this.http
      .get<ApiResponse<T>>(`${this.getApiPath()}/${this.endpoint}/${id}`)
      .pipe(
        retry(1),
        map((data: ApiResponse<T>) => {
          return vm.buildOneResponse(data);
        }),
        shareReplay()
      );
  }

  public get(): Observable<ApiResponse<T[]>> {
    const vm = this;
    return this.http
      .get<ApiResponse<T[]>>(`${this.getApiPath()}/${this.endpoint}`)
      .pipe(
        retry(1),
        map((data: ApiResponse<T[]>) => {
          return vm.buildManyResponse(data);
        }),
        shareReplay()
      );
  }

  public getByPrivateApiPath(extraUrl: string = ''): Observable<ApiResponse<T[]>> {
    let vm = this;
    return this.http
      .get<ApiResponse<T[]>>(`${this.getPrivateApiPath()}/${this.endpoint}/${extraUrl}`)
      .pipe(
        retryWhen(errors => 
          errors.pipe(
            mergeMap(error => (error.status === 401) ? throwError(error) : of(error)),
            take(2)
        )),
        map((data: ApiResponse<T[]>) => {
          return vm.buildManyResponse(data);
        }),
        shareReplay()
      );
  }

  public putByPrivateApiPath(extraUrl: string = '', body: any): Observable<ApiResponse<T>> {
    let vm = this;
    return this.http
      .put<ApiResponse<T>>(`${this.getPrivateApiPath()}/${this.endpoint}/${extraUrl}`, body)
      .pipe(
        retryWhen(errors => 
          errors.pipe(
            mergeMap(error => (error.status >= 400) ? throwError(error) : of(error)),
            take(2)
        )),
        map((data: ApiResponse<T>) => {
          return vm.buildOneResponse(data);
        }),
        shareReplay()
      );
  }

  public post(extraUrl: string = '', body: any): Observable<ApiResponse<T>> {
    let vm = this;
    return this.http
      .post<ApiResponse<T>>(`${this.getApiPath()}/${this.endpoint}/${extraUrl}`, body)
      .pipe(
        retry(1),
        map((data: ApiResponse<T>) => {
          return vm.buildOneResponse(data);
        }),
        shareReplay()
      );
  }

  public getWithExtraUrlAndParams(extraUrl: string , params: any) {
    return this.http.get<any>(`${this.getApiPath()}/${this.endpoint}${extraUrl}`, { params}).pipe(shareReplay());
  }

  public getPrivate() {
    return this.http.get<any>(`${this.getPrivateApiPath()}/${this.endpoint}`).pipe(shareReplay());
  }

  protected buildOneResponse(data: ApiResponse<T>) {
    const tempData = data.data;
    delete (data.data);

    const response = Object.assign(new ApiResponse<T>(), data);
    response.data = this.parseDataToObject(tempData);
    return response;
  }

  protected buildManyResponse(data: ApiResponse<T[]>) {
    const tempData = data.data;
    delete (data.data);

    const response = Object.assign(new ApiResponse<T[]>(), data);
    response.data = tempData.map(instance => this.parseDataToObject(instance));
    return response;
  }

  protected parseDataToObject(data: T): T {
    return data;
  }
}
