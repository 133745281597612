import { ExternalLink } from '../external-link/external-link';
import { HomeCategory } from '../home-category/home-category';
import { Translations } from '../translations/translations';

export class InitialSetup {
  public id: number;
  public property_id: number;
  public frontend_language: string;
  public currency: string;
  public template: any;
  public home_image: string;
  public home_image_url: string;
  public languages: Language[];
  public logo_image: string;
  public logo_image_url: string;
  public translations: Translations;
  public chat_idle_timeout: number;
  public chatbot: boolean;
  public created_at: string;
  public custom_actions: any;
  public show_provider_list: boolean;
  public show_catalog_list: boolean;
  public home_categories: HomeCategory[];
  public reservation_mode_id: string;
  public allow_orders: boolean;
  public allow_reservations: boolean;
  public allow_chat: boolean;
  public allow_softphone: boolean;
  public analytics_code: string;
  public required_revalidation: boolean;
  public timezone: string;
  public default_length_of_stay: number;
  public authentication_fields: string;
  public allow_order_to_diners: boolean;

  static instanceFromJson(data: any): InitialSetup {
    const instance = new InitialSetup();
    instance.id = data.id;
    instance.property_id = data.property_id;
    instance.frontend_language = data.frontend_language;
    instance.currency = data.currency;
    instance.template = data.template;
    instance.home_categories = data.home_categories.map(link => HomeCategory.instanceFromJson(link));
    instance.home_image = data.home_image;
    instance.home_image_url = data.home_image_url;
    instance.languages = data.languages.map(lang => Language.instanceFromJson(lang));
    instance.logo_image = data.logo_image;
    instance.logo_image_url = data.logo_image_url;
    instance.translations = Translations.instanceFromJson(data.translations);
    instance.chat_idle_timeout = data.chat_idle_timeout;
    instance.chatbot = data.chatbot;
    instance.created_at = data.created_at;
    instance.custom_actions = data.custom_actions;
    instance.show_provider_list = data.show_provider_list;
    instance.show_catalog_list = data.show_catalog_list;
    instance.reservation_mode_id = data.reservation_mode_id;
    instance.allow_orders = data.allow_orders;
    instance.allow_reservations = data.allow_reservations;
    instance.allow_chat = data.allow_chat;
    instance.allow_softphone = data.allow_softphone;
    instance.analytics_code = data.analytics_code;
    instance.required_revalidation = data.required_revalidation;
    instance.timezone = data.timezone;
    instance.default_length_of_stay = data.default_length_of_stay;
    instance.authentication_fields = data.authentication_fields;
    instance.allow_order_to_diners = data.allow_order_to_diners;

    return instance;
  }
}

export class Language {
  public id: string;
  public code: string;
  public name: string;

  static instanceFromJson(data: any): Language {
    const instance = new Language();
    instance.id = data.id;
    instance.code = data.code;
    instance.name = data.name;

    return instance;
  }
}
