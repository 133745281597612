export class Widget {
  public id: number;
  public type: string;
  public image: string;
  public image_svg: string;
  public entity_id: number;
  public position: number;
  public show_title: boolean;
  public entity: any;
  public visible_in: string;

  static instanceFromJson(data: any): Widget {
    let instance = new Widget();
    instance.id = data.id;
    instance.type = data.type;
    instance.image = data.image;
    instance.image_svg = data.image_svg;
    instance.entity_id = data.entity_id;
    instance.position = data.position;
    instance.show_title = data.show_title;
    instance.entity = data.entity;
    instance.visible_in = data.visible_in;

    return instance;
  }
}
