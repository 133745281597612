export class CategoryDisplayType {
  public id: number;
  public type: string;
  public aspect_ratio: string;
  public params: number;

  static instanceFromJson(data: any): CategoryDisplayType {
    let instance = new CategoryDisplayType();
    instance.id = data.id;
    instance.type = data.type;
    instance.aspect_ratio = data.aspect_ratio;
    instance.params = data.params;

    return instance;
  }
}