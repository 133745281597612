import { Injectable } from '@angular/core';
import { DEFAULT_ANALYTICS_CODE } from 'src/env';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  static loadGoogleAnalytics(trackingID: string): void {
    let tracking_id = trackingID !== null && trackingID !== '' ? trackingID : DEFAULT_ANALYTICS_CODE;
    let gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ tracking_id }`);

    let gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ tracking_id }\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
  }

}