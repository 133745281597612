import { ConstantsService } from 'src/app/services/constants/constants.service';
import { Translations } from '../translations/translations';

export class OrderProduct {

	//Datos desde backend
	public id: number;
	public category_id: number;
	public order_id: number;
	public price: number;
	public category_name: string;
	public product_output_interval_code: string;
	public code: string;
	public product_type_id: string;
	public image_url: string;
	
	//Ambos
	public amount: number;
	public product_id: number;
	public notes: string;
	public product_name: string;
	public product_description: string;
	public temporary_id: string;
	public bill_diner_name: string;
	public diner_number: string;
	public order_product_state_code: string;

	//Para manejo de frontned
	public index: number;
	public size: string;
	public total: number;
	public catalog_id: number;
	public is_owner: boolean;
	public is_read_only: boolean;

	static initProductFromOrder(data: any): OrderProduct {
		let instance = new OrderProduct();
		instance.id = data.id;
		instance.category_id = data.category_id;
		instance.order_id = data.order_id;
		instance.price = data.price;
		instance.category_name = data.category_name;
		instance.amount = data.amount;
		instance.product_id = data.product_id;
		instance.notes = data.notes || '';
		instance.product_name = data.product_name;
		instance.index = data.index;
		instance.size = data.size;
		instance.total = data.price * data.amount;
		instance.catalog_id = data.catalog_id;
		instance.product_output_interval_code = data.product_output_interval_code;
		instance.code = data.code;
		instance.product_type_id = data.product_type_id;
		instance.image_url = data.image_url ?? data.product_image_url;
		instance.product_description = data.product_description;
		instance.temporary_id = data.temporary_id;
		instance.is_read_only = data.is_read_only;
		instance.is_owner = data.is_owner;
		instance.bill_diner_name = data.bill_diner_name ?? data?.bill_diner?.diner_name ?? null;
		instance.order_product_state_code = data.order_product_state_code;
		instance.diner_number = data.diner_number ?? data?.bill_diner?.diner_number ?? null;
		return instance;
	}

	// chhhh - TODO: refactorizar para pedir lenguajes disponibles
	public setTranslations(productProp: string, translationProp: string, translations: Translations){
		this[productProp] = translations.es ? (translations.es[translationProp]? translations.es[translationProp]: '') : '';
		this[productProp] += translations.en ? (translations.en[translationProp]? (this[productProp]? ',': '') + translations.en[translationProp]: '') : '';
		this[productProp] += translations.pt ? (translations.pt[translationProp]? (this[productProp]? ',': '') + translations.pt[translationProp]: '') : '';
		this[productProp] += translations.fr ? (translations.fr[translationProp]? (this[productProp]? ',': '') + translations.fr[translationProp]: '') : '';
		this[productProp] += translations.de ? (translations.de[translationProp]? (this[productProp]? ',': '') + translations.de[translationProp]: '') : '';
		this[productProp] += translations.ru ? (translations.ru[translationProp]? (this[productProp]? ',': '') + translations.ru[translationProp]: '') : '';
	}

	public getTranslations(productProp: string, i18n: string){
		let translations = this[productProp].split(',');
		
		switch(i18n){
			case ConstantsService.LANGUAGE_ES_INDEX:
				return translations[0];

			case ConstantsService.LANGUAGE_EN_INDEX:
				return translations[1];

			case ConstantsService.LANGUAGE_PT_INDEX:
				return translations[2];

			case ConstantsService.LANGUAGE_FR_INDEX:
				return translations[3];
		}
	}

	public setNewOrderProduct() {
		this.index = null;
		this.product_id = null;
		this.product_name = null;
		this.size = null;
		this.amount = 1;
		this.notes = '';
		this.total = 0;
	}
}