import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {map, retryWhen, shareReplay, mergeMap, take} from 'rxjs/operators';
import {ApiResponse} from 'src/app/models/api-response/api-response';
import {GeneralService} from '../general/general.service';
import {Reservation, MyReservations} from '../../models';
import { StorageService } from '../../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService extends GeneralService<any> {

  public currentReservation: Reservation;
  public originalReservation: Reservation;
  public reservations: Array<any>;

  constructor(protected http: HttpClient,
    private storageService: StorageService) { 
    super(http, 'restaurant-reservations');
  }

  generateNewReservation(newReservation) {
    this.currentReservation = newReservation;
  }

  setOriginalReservation(originalReservation) {
    this.originalReservation = originalReservation;
  }

  public getCurrentReservation() {
    return this.currentReservation;
  }

  public getOriginalReservation() {
    return this.originalReservation;
  }

  public reservationsByGuestId(): Observable<any> {
    const vm = this;
    return this.http
      .get<any>(`${this.getPrivateApiPath()}/restaurant-reservations/by-guest`)
      .pipe(
        shareReplay(),
        map((data: ApiResponse<MyReservations>) => {
          return vm.buildOneResponse(data);
        })
      );
  }

  public changeState(restaurant_reservation: any): Observable<any> {
    return this.putByPrivateApiPath('change-state', restaurant_reservation);
  }

  public checkAvailability(optionsSelected: any): Observable<any> {
    return this.post('check-availability', optionsSelected);
  }

  public confirmReservation(body: any): Observable<any> {
    return this.reservationPost('confirm-reservation', body);
  }

  public scheduleChange(body: any): Observable<any> {
    return this.reservationPost('schedule-change', body);
  }

  public personalInformationChange(body: any): Observable<any> {
    return this.reservationPost('personal-information-change', body);
  }

  public reservationPost(extraUrl: string = '', body: any): Observable<ApiResponse<any>> {
    let vm = this;
    return this.http
      .post<ApiResponse<any>>(`${this.getApiPath()}/${this.endpoint}/${extraUrl}`, body)
      .pipe(
        retryWhen(errors => 
          errors.pipe(
            mergeMap(error => (error.status >= 400) ? throwError(error) : of(error)),
            take(2)
        )),
        map((data: ApiResponse<any>) => {
          return vm.buildOneResponse(data);
        }),
        shareReplay()
      );
  }

  public session(body: any): Observable<any> {
    body.reservation_session_token = this.storageService.get('restaurant-reservation-session');

    return this.reservationPost('restaurant-reservation-session', body)
      .pipe(
        shareReplay(),
        map((data: ApiResponse<any>) => {
          this.storageService.set('restaurant-reservation-session', data.data.token);
          return data;
        })
      );
  }

  public handleErrors(error, router, provider) {
    if (error.status === 409) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.RESERVATION_TIME_RESERVED', goTo: '/reservation/' + provider + '/schedule', button: 'BACK'}});
    } else if (error.status === 400) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.RESERVATION_VALIDATION_ERROR', goTo: '/reservation/' + provider + '/schedule', button: 'BACK'}});
    } else if (error.status === 410) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.RESERVATION_NOT_FOUND', goTo: '/reservation/' + provider + '/schedule', button: 'BACK'}});
    } else if (error.status === 411) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.CANCELLED_RESERVATION', goTo: '/my-reservations', button: 'BACK'}});
    } else if (error.status === 412) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.ORIGINAL_RESERVATION_TIME_PASSED', goTo: '/my-reservations', button: 'BACK'}});
    } else if (error.status === 418) {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.MAX_CONFIRMED_RESERVATIONS_ERROR', goTo: '/reservation/' + provider + '/schedule', button: 'BACK'}});
    } else {
      router.navigate(['/errors/generic-error'], {state: {title: 'ERROR.RESERVATION_ERROR', message: 'ERROR.RESERVATION_TIME_PASSED', goTo: '/reservation/' + provider + '/schedule', button: 'BACK'}});
    }
  }

}