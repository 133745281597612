import { Injectable } from "@angular/core";
import { RsCatalogsService } from "../services/rs-catalogs/rs-catalogs.service";

@Injectable()
export class RsCatalogsProvider {
  private rsCatalogs = null;

  constructor(
    private rsCatalogsService: RsCatalogsService
  ) { }

  public getRsCatalogs() {
    return this.rsCatalogs;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.rsCatalogsService.getAll().subscribe(response => {
        this.rsCatalogs = response.data;
        resolve(true);
      });
    });
  }
}