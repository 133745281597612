import { Translations } from '../translations/translations'

export class OrderState {
  public id: number;
  public created_at: string;
  public updated_at: string;
  public translations: Translations;
  public slug: string;

  static instanceFromJson(data: any): OrderState {
    let instance = new OrderState();
    instance.id = data.id;
    instance.created_at = data.created_at;
    instance.updated_at = data.updated_at;
    instance.translations = Translations.instanceFromJson(data.translations);
    instance.slug = data.slug;

    return instance;
	}

  static initBasicOrderState(data: any) {
    let instance = new OrderState();
    instance.id = data.id;
    instance.slug = data.slug;

    return instance;
  }
}