import { Translations } from '../translations/translations';

export class Amenity {
  public id: number;
  public property_id: number;
  public image_name: string;
  public image_url: string;
  public translations: Translations;
  public custom_actions: [];
  public allow_orders: boolean;
  public updated_at: string;
  public created_at: string;

  //Translations
  public name: string;

  public static basicInstance(id: number, name: string, image_url: string) {
    const instance = new this();
    instance.id = id;
    instance.name = name;
    instance.image_url = image_url;
    return instance;
  }

}