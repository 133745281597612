import { ConstantsService } from 'src/app/services/constants/constants.service';
import { Translations } from '../translations/translations';
import { OrderProduct } from '../order-product/order-product';

export class OrderProductsByOwner {
	//Datos para manejo de frontned
    public next_index: number;
	public current_bill_products: OrderProduct[];
	public another_bill_products: OrderProduct[];
    public order_products: OrderProduct[];


    static init(orderProducts): OrderProductsByOwner {
		let instance = new OrderProductsByOwner();
        instance.order_products = [];
        instance.current_bill_products = [];
        instance.another_bill_products = [];
        if(orderProducts.length > 0) {
            instance.setOrderProducts(orderProducts);
        }
		return instance;
	}


    public setOrderProducts(products) {
        this.next_index = 1;
		products.forEach(givenOrderProduct => {
            const orderProduct = OrderProduct.initProductFromOrder(givenOrderProduct);
			orderProduct.index = this.next_index;
            this.order_products.push(orderProduct)
			this.next_index++
            if(orderProduct.is_read_only) {
                this.another_bill_products.push(orderProduct)
            } else {
                this.current_bill_products.push(orderProduct)
            }
        })
    }

    public removeProduct(orderProduct: OrderProduct) {
        if(orderProduct.is_read_only) {
            this.another_bill_products = this.removeProductAux(orderProduct, this.another_bill_products);
        } else {
            this.current_bill_products = this.removeProductAux(orderProduct, this.current_bill_products);
        }
        this.order_products = this.removeProductAux(orderProduct, this.order_products, true);
        this.next_index--;
    }

    public addProduct(newProduct: OrderProduct) {
        if(newProduct.is_read_only) {
            this.another_bill_products.push(newProduct);
        } else {
            this.current_bill_products.push(newProduct);
        }
        this.order_products.push(newProduct);
    }

    private removeProductAux(orderProduct: OrderProduct, orderProductsRef: OrderProduct[], decreaseProductIndex: boolean = false): OrderProduct[] {
		return orderProductsRef.filter(product => {
			if(product.index != orderProduct.index){
				if(decreaseProductIndex && product.index > orderProduct.index){
					product.index--;
				}
				return product;
			}
		})
    }

}