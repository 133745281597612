import { Translations } from '../translations/translations';

export class Restaurant {
  public id: number;
  public property_id: number;
  public name: string;
  public slug: string;
  public image_name: string;
  public image_url: string;
  public translations: Translations;
  public template: any;
  public template_id: number;
  public show_title: boolean;
  public allows_reservation: boolean;
  public allow_orders: boolean;
  public reservation_period: number;
  public created_at: string;
  public updated_at: string;
  public custom_actions: any[];
  public allows_reserv_cate: boolean;
  public allow_reservations_front: boolean;
  public visible_in:  string;
  public allow_order_to_diners: boolean;
}
