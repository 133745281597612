import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralService } from '../general/general.service';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';
import { Router } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { Restaurant } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class BillsService extends GeneralService<any> {

  constructor(
    protected http: HttpClient,
    protected storage: StorageService,
    private router: Router,
    private modalService: ModalService,
  ){
    super(http, 'bills');
  }

  public associate(associationCode: string, dinerName: string, token?: string, fcmToken?: string, restaurant_id?: number) {
    const body = {'association_code': associationCode, 'diner_name': dinerName, 'fcm_token': fcmToken, 'restaurant_id': restaurant_id};
    if(token) {
      this.headers = new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Bill-diner-token': token
      });
    }
    return this.http.post<ApiResponse<any>>(`${this.getApiPath()}/bills/associate`, body, {headers: this.headers});
  }

  getMyStay() {
    const token: string = this.storage.get(ConstantsService.MY_STAY_TOKEN, null);
    if(token) {
      this.headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Bill-diner-token': token
      });
    }
    const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
    return this.http.get<ApiResponse<any>>(`${this.getApiPath()}/restaurant-stays/actives-by-table?language_code=${languageCode}`, {headers: this.headers});
  }

  validateTokenAndRestaurant(callbackFn: any, currentRestaurant: Restaurant, orderRestaurant: Restaurant, currentProductData){
    const vm = this;
    const myStayToken: string = this.storage.get(ConstantsService.MY_STAY_TOKEN, null);
    if (!myStayToken) {
      this.modalService.validateOrBack(() => vm.router.navigate(
          [`/restaurants/my-stay/associate/${currentRestaurant.slug}`],
              { state: {currentProductData} }),
        'MYSTAY.DONT_HAVE_MY_STAY_TOKEN_HEADER',
        'MYSTAY.DONT_HAVE_MY_STAY_TOKEN_DESCRIPTION'
      );
    } else if(orderRestaurant && currentRestaurant.id != orderRestaurant.id){
      this.modalService.validateOrBack(() => vm.router.navigate([`/restaurants/my-stay/associate/${currentRestaurant.slug}`]),
      'ERROR.DIFFERENT_RESTAURANT_HEADER', 
      'ERROR.DIFFERENT_RESTAURANT_DESCRIPTION',
      {orderRestaurant: orderRestaurant.name, currentRestaurant: currentRestaurant.name}
      );
    } else {
      callbackFn();
    }
  }

  closeBillRequest(billData) {
    const token: string = this.storage.get(ConstantsService.MY_STAY_TOKEN, null);
    const languageCode = this.storage.get(ConstantsService.LOCALSTORAGE_LANGUAGE_INDEX);
    if(token) {
      this.headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Bill-diner-token': token
      });
    }
    return this.http.post<ApiResponse<any>>(`${this.getApiPath()}/bills/request-closure?language_code=` + languageCode, billData, {headers: this.headers});
  }

  getTaxRegimes() {
    return this.http.get<ApiResponse<any>>(`${this.getApiPath()}/tax-regimes`);
  }

  parseTaxAuthorities(url: string) {
    const body = {
      'url': url
    }
    return this.http.put<ApiResponse<any>>(`${this.getApiPath()}/bills/tax-authorities`, body);
  }

  getBillTableStatement() {
    const token: string = this.storage.get(ConstantsService.MY_STAY_TOKEN, null);
    if(token) {
      this.headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Bill-diner-token': token
      });
    }
    return this.http.get<ApiResponse<any>>(`${this.getApiPath()}/bills/statement`,{headers: this.headers});
  }
}
