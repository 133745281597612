import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SoftphoneSessionService } from 'src/app/services/softphone/softphone-session.service';

@Component({
  selector: 'app-softphone-pin',
  templateUrl: './softphone-pin.component.html',
  styleUrls: ['./softphone-pin.component.scss'],
})
export class SoftphonePinComponent {

  private onCall = false; 

  constructor(private router: Router, private softphoneSessionService: SoftphoneSessionService) {
    this.softphoneSessionService.onCallStatusChanges.subscribe(onCall => this.onCall = onCall);
  }

  visible() {
    if(this.softphoneSessionService.isOnPhoneScreen()) {      
        return false;
    } else {
        if(!this.onCall) {
            this.softphoneSessionService.destroy();
        }
        return this.onCall;
    }
  }

  return() {
    this.router.navigate(['contact/softphone-main']);
  }

  hang() {
    this.softphoneSessionService.hang();
  }
}