import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general/general.service';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService extends GeneralService<any>{

  public productCategories: any[];

  constructor(
    protected http: HttpClient,
  ){
    super(http, 'product-categories');
  }

  getAll() {
    let data;
    if (this.productCategories){
      data = this.productCategories;
    }else{
      data = this.get();
      data.subscribe( response => {
        this.productCategories = response;
      });
    }
    return data;
  }
}
