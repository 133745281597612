import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, EMPTY} from 'rxjs';
import { InitialSetup } from 'src/app/models/initial-setup/initial-setup';
import { GeneralService } from '../general/general.service';
import {catchError, map, retry, shareReplay} from 'rxjs/operators';
import {ApiResponse} from '../../models/api-response/api-response';
import {Router} from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';

@Injectable({
  providedIn: 'root'
})
export class InitialSetupService extends GeneralService<InitialSetup>{

  private initialSetupSubject = new BehaviorSubject(undefined);
  public initialSetup = this.initialSetupSubject.asObservable();

  constructor(
    protected http: HttpClient,
    private router: Router,
    protected storage: StorageService
  ) {
    super(http, 'initial-setup');
  }

  async setInitialSetup() {
    const vm = this;
    if (!this.initialSetupSubject.getValue()) {

      if(this.storage.has(ConstantsService.MY_STAY_TOKEN)) {
        const token = this.storage.get(ConstantsService.MY_STAY_TOKEN);
        this.headers = new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Bill-diner-token': token
        });
      }

      const response = await this.http
        .get<ApiResponse<InitialSetup>>(`${this.getApiPath()}/${this.endpoint}`, {headers: this.headers})
        .pipe(
          retry(1),
          map((data: ApiResponse<InitialSetup>) => {
            return vm.buildOneResponse(data);
          }),
          shareReplay(),
          catchError( (err: HttpErrorResponse) => {
            if (err.status === 500) {
              this.router.navigate(['/errors/something-went-wrong']);
            }
            return EMPTY;
          })
        ).toPromise();
      // let response = await this.unique().toPromise();
      this.initialSetupSubject.next(response);
      AnalyticsService.loadGoogleAnalytics(response?.data?.analytics_code);
    }
  }

  setNewValue(field: string, data: any) {
    let currentInitialSetup = this.initialSetupSubject.getValue();
    currentInitialSetup.data[field] = data;

    this.initialSetupSubject.next(currentInitialSetup);
  }

  protected parseDataToObject(data) {
    return InitialSetup.instanceFromJson(data);
  }
}