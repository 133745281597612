import { Order } from '../order/order';

export class CurrentOrder extends Order {
  static initCurrentOrder(providerName, currency, orderProducts = []) {
	return new CurrentOrder(currency, providerName, orderProducts);
  }

  	// Analizar si este modelo sigue teniendo sentido
	constructor(currency, providerName, products = [], notes = '') {
		super(currency, providerName, products, notes)
	}
}