import { Translations } from '../translations/translations';

export class RoomService {
    public id: number;
    public property_id: number;
    public name: string;
    public image_name: string;
    public image_url: string;
    public translations: Translations;
    public custom_actions: [];
    public allow_orders: boolean;
    public created_at: string;
    public updated_at: string;
}
