import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GeneralService } from '../general/general.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends GeneralService<any>{
  public products = { data: {}};

  constructor(protected http: HttpClient){
    super(http, 'products');
  }

  public getByCatalogId(id): Observable<any> {
    const params = new HttpParams().set('catalog_id', id);
    let data;
    if (this.products && this.products.data[id]){
      data = { data: this.products.data[id]};
    }else{
      data = this.getWithExtraUrlAndParams(`/by-catalog/${id}`, params);
      data.subscribe( response => {
        this.products.data[id] = response.data;
      });
    }
    return data;
  }
}
