export class Reservation {

  public id: number;
  public room_number: number;
  public guest: string;
  public email: string;
  public phone: string;
  public phone_number: string;
  public provider: any;
  public date: string;
  public time: string;
  public seats: number;
  public is_guest: boolean = false;
  public reservation_token: string;
  public device_id: number;
  public suggested_hours: Array<string>;
  public waiting_list_enabled: boolean = false;
  public waiting_list_reservation_token: string;
  public join_waiting_list: boolean = false;
  public language_code: string;
  public last_restaurant_reservation_id: number;
  public last_name: string;
  public comments: string;
  public food_issues: string;
  public locking_time: number;
  public locked_expiration_date: Date;
  public reservation_session_token: string;

  static instanceFromJson(data: any): Reservation {
    let instance = new Reservation();
    instance.id = data.id;
    instance.room_number = data.room_number;
    instance.guest = data.guest;
    instance.email = data.email;
    instance.phone = data.phone;
    instance.provider = data.provider;
    instance.date = data.date;
    instance.time = data.time;
    instance.seats = data.seats;
    instance.device_id = data.device_id;
    instance.language_code = data.language_code;
    instance.comments = data.comments;
    instance.food_issues = data.food_issues;
    instance.locking_time = data.locking_time;
    instance.reservation_session_token = data.reservation_session_token;
    
    return instance;
  }

}