import { Catalog } from '../catalog/catalog';
import { Translations } from '../translations/translations';
import { ProductCategory } from '../product-category/product-category';

export class Product {
    public id: number;
    public product_category_id: number;
    public property_id: number;
    public position: number;
    public price: number;
    public image_name: string;
    public image_url: string;
    public catalogs: Catalog[];
    public product_category: ProductCategory;
    public translations: Translations;
    public created_at: string;
    public updated_at: string;
    public disabled: boolean;
    public custom_actions: [];
    public product_output_interval_code: string;
	public code: string;
	public product_type_id: string;

    //Translations
    public name: string;
    public short_description: string;
    public long_description: string;
    public ingredients: string;
    public presentation: string;

    //Pictograms
    public pictographs: any;
    public pictograph_imgs: [];

    //Notes and Amount Aux
    public notes_aux: string;
    public amount_aux: number;

    public static basicInstance(id: number, name: string, image_url: string, short_description: string, price: number) {
        let instance = new this();
        instance.id = id;
        instance.name = name;
        instance.image_url = image_url;
        instance.short_description = short_description;
        instance.price = price;
        
        return instance;
    }

    public static majorInstance(
        id: number, name: string, product_category_id: number, image_url, short_description: string, 
        long_description: string, ingredients: string, presentation: string, price: number
    ) {
        let instance = new this();
        instance.id = id;
        instance.name = name;
        instance.product_category_id = product_category_id;
        instance.image_url = image_url;
        instance.short_description = short_description;
        instance.long_description = long_description;
        instance.ingredients = ingredients;
        instance.presentation = presentation;
        instance.price = price;
        
        return instance;
    }
}