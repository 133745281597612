import { Injectable } from '@angular/core';
import { Subscription, timer, Observable, interval, BehaviorSubject } from 'rxjs';
import { map  } from 'rxjs/operators';
import { RING_FILE, BUSY_FILE, PH_ST_REGISTERED, PH_ST_UNREGISTERED, SE_ST_PROGRESS, SE_ST_ACCEPTED, SE_ST_TERMINATED, SE_ST_CANCELED, SE_ST_REJECTED, SE_ST_FAILED, SE_ST_BYE, SE_ST_DTMF, SF_ST_WAITING_FOR_USER, SF_ST_DIALING, PH_ST_CONNECTED, PH_ST_DISCONNECTED, PH_ST_REGISTRATIONFAILED, T_ST_ERROR } from './softphone-session.config';

declare var SIP: any;

@Injectable({
  providedIn: 'root'
})
export class SoftphoneSessionService {

    onCallStatusChanges = new BehaviorSubject<boolean>(false);
    phoneStatusChanges = new BehaviorSubject<string>(PH_ST_DISCONNECTED);
    callStateChanges = new BehaviorSubject<string>(SF_ST_WAITING_FOR_USER);
    elapsedTimeChanges = new BehaviorSubject<string>('00:00');
    private ringing = new Audio(RING_FILE);
    private busy = new Audio(BUSY_FILE);
    private phone = null;
    private phoneSession = null;
    private onPhoneScreen = false;
    private extension = '';

    public isPristine() {
        return !this.phone;
    }

    initialize(conf: any, device: any){

        this.setOnCall(false);
        this.setPhoneStatus(PH_ST_DISCONNECTED);
        this.setCallState(SF_ST_WAITING_FOR_USER);

        this.phone = new SIP.UA({
          autostart: false,
          password: conf.password,
          authorizationUser: conf.user,
          displayName: device.guest + '-' + device.room,
          contactName: device.guest + '-' + device.room,
          userAgentString: 'contactless',
          uri: 'sip:' + conf.user + '@' + this.extractDomain(conf.sip_server),
          transportOptions: {
              wsServers: [conf.sip_server],
              traceSip: true,
              maxReconnectionAttempts: 60,
              reconnectionTimeout: 10
          },
          hackWssInTransport: true,
          hackIpInContact: true,
          log: {
              level: 10
          },
          stunServers: conf.stun_servers,
          sessionDescriptionHandlerFactoryOptions: {
              constraints: {
                  audio: true,
                  video: false
              }
          }
        });

        this.phone.on(PH_ST_CONNECTED, () => this.phoneStatusChanges.next(PH_ST_CONNECTED));
        this.phone.on(PH_ST_REGISTERED, () => this.phoneStatusChanges.next(PH_ST_REGISTERED));
        this.phone.on(PH_ST_UNREGISTERED, () => this.phoneStatusChanges.next(PH_ST_UNREGISTERED));        
        this.phone.on(PH_ST_DISCONNECTED, () => this.phoneStatusChanges.next(PH_ST_DISCONNECTED));
        this.phone.on(PH_ST_REGISTRATIONFAILED, () => this.phoneStatusChanges.next(PH_ST_REGISTRATIONFAILED));
        this.phone.transport.on(T_ST_ERROR, () => this.phoneStatusChanges.next(T_ST_ERROR));
    }

    public destroy() {
        if(this.phone) {
            this.phone.stop();
            this.phone = null;
        }
    }

    public start() {
        if(this.phone && !this.phone.isRegistered()) {
            this.phone.start();
        }
    }

    public stop() {
        if(this.phone && this.phone.isRegistered()) {
            this.phone.stop();
        }
    }    

    public dial(extension: string, device: any) {

        if(!extension) {
            return;
        }

        this.extension = extension;

        var options = {
            extraHeaders: [ 
                'gueststatus: 1',
                'guestname: ' + device.guest,
                'guestroom: ' + device.room
            ]
        };
    
        this.phoneSession = this.phone.invite(extension, options);
        this.setCallState(SF_ST_DIALING);
        this.setOnCall(true);
        this.ringing.play();

        this.phoneSession.on(SE_ST_PROGRESS, (e: any) => {
            this.setCallState(SE_ST_PROGRESS);
            this.setOnCall(true);
        });
    
        let cronomenter: Subscription;

        this.phoneSession.on(SE_ST_ACCEPTED, (e: any) => {
            this.ringing.pause();
            this.plugAudio(this.phoneSession.sessionDescriptionHandler.peerConnection);
            this.setCallState(SE_ST_ACCEPTED);
            this.setOnCall(true);
            cronomenter = this.createCronometer().subscribe(([minutes, seconds]) => {
                this.elapsedTimeChanges.next((minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds));
            });
        });
    
        this.phoneSession.on(SE_ST_CANCELED, (e: any) => {
            this.ringing.pause();
            this.setOnCall(false);
            this.setCallState(SE_ST_CANCELED);
        });
    
        this.phoneSession.on(SE_ST_TERMINATED, (e: any) => {
            this.ringing.pause();
            this.setOnCall(false);
            this.setCallState(SE_ST_TERMINATED);
            if (cronomenter) {
                cronomenter.unsubscribe();
            }
            timer(2000).subscribe(() => {
              this.setCallState(SF_ST_WAITING_FOR_USER);
              this.elapsedTimeChanges.next('00:00');
            });
        });
    
        this.phoneSession.on(SE_ST_FAILED, (e: any) => {
            this.ringing.pause();
            this.setOnCall(false);
            this.setCallState(SE_ST_FAILED);
        });
    
        this.phoneSession.on(SE_ST_REJECTED, (e: any) => {
            this.ringing.pause();
            this.busy.play();
            timer(5000).subscribe(() => {
                this.busy.pause();
                this.setOnCall(false);
                this.setCallState(SE_ST_REJECTED);    
            });
        });
    
        this.phoneSession.on(SE_ST_BYE, (e: any) => {
            this.setOnCall(false);
            this.setCallState(SE_ST_BYE);
        });
    
        this.phoneSession.on(SE_ST_DTMF, (e: any) => {
            this.setOnCall(false);
            this.setCallState(SE_ST_DTMF);
        });
    }

    public hang() {
        if(this.phoneSession) {
            this.phoneSession.terminate();
            this.phoneSession = null;
        }
    }

    public isOnPhoneScreen() {
        return this.onPhoneScreen;
    }

    public setOnPhoneScreen(onPhoneScreen: boolean) {
        this.onPhoneScreen = onPhoneScreen;
    }

    public getLastExtension() {
        return this.extension;
    }

    private plugAudio(pc: any) {
        const voice = new Audio();
        if (pc.getReceivers) {
          const stream = new window.MediaStream();
          pc.getReceivers().forEach(receiver => {
              if (receiver.track) {
                  stream.addTrack(receiver.track);
              }
          });
          voice.srcObject = stream;
        } else {
          voice.srcObject = pc.getRemoteStreams()[0];
        }
        voice.play();
    }

    private createCronometer(): Observable<number[]>{
        return interval(1000).pipe(map(tick => {
            const seconds = tick % 60;
            const minutes = Math.round(tick / 60);
            return [minutes, seconds];
        }));
    }

    private setPhoneStatus(phoneStatus: string) {
        this.phoneStatusChanges.next(phoneStatus);
    }

    private setCallState(callState: string) {
        this.callStateChanges.next(callState);
    }

    private setOnCall(onCall: boolean) {
        this.onCallStatusChanges.next(onCall);
    }    

    private extractDomain(url: string) {
        let domain: string;
        if (url.indexOf('://') > -1) {
            domain = url.split('/')[2];
        } else {
            domain = url.split('/')[0];
        }
        return domain.split(':')[0];
    }
}
