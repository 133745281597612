import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(private platform: Platform) { }

  public isMobile(){
    return !this.platform.platforms().includes('tablet') && this.platform.platforms().includes('mobile')
  }
}
