import { Injectable } from "@angular/core";
import { RestaurantsService } from "../services/restaurants/restaurants.service";

@Injectable()
export class RestaurantsProvider {
  private restaurants = null;

  constructor(
    private restaurantsService: RestaurantsService
  ) { }

  public getRestaurants() {
    return this.restaurants;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.restaurantsService.getAll().subscribe(response => {
        this.restaurants = response.data;
        resolve(true);
      });
    });
  }
}