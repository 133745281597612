import { Component, OnInit } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';

import { RouteRewriteRulesProvider } from './initialization';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  public routeRewriteRules: any[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private routeRewriteRulesProvider: RouteRewriteRulesProvider,
    private router: Router) {
    this.initializeApp();
    this.routeRewriteRules = this.routeRewriteRulesProvider.getRules();

    // FIX: Esto es para determinar si tiene que sobreescribir una URL mal ingresada (caso QRs mal generados a mano antes de tener
    // el generador de QRs o QRs generados a mano que perdieron compatibilidad cuando se migro de eCarta a Contactless)
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const rule = this.routeRewriteRules.find(currentRule => {
          const re = new RegExp(currentRule.pattern);
          return re.test(decodeURI(event.url));
        });

        if (rule != null) {
          this.router.navigateByUrl(this.router.parseUrl(rule.substitution));
        }
      }
    });
  }

  ngOnInit() {
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

}
