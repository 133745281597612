import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, EMPTY} from 'rxjs';
import { InitialSetup } from 'src/app/models/initial-setup/initial-setup';
import { GeneralService } from '../general/general.service';
import {catchError, map, retry, shareReplay} from 'rxjs/operators';
import {ApiResponse} from '../../models/api-response/api-response';
import {Router} from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class InitialSetupService extends GeneralService<InitialSetup>{

  private initialSetupSubject = new BehaviorSubject(undefined);
  public initialSetup = this.initialSetupSubject.asObservable();

  constructor(
    protected http: HttpClient,
    private router: Router
  ) {
    super(http, 'initial-setup');
  }

  async setInitialSetup() {
    const vm = this;
    if (!this.initialSetupSubject.getValue()) {
      const response = await this.http
        .get<ApiResponse<InitialSetup>>(`${this.getApiPath()}/${this.endpoint}`)
        .pipe(
          retry(1),
          map((data: ApiResponse<InitialSetup>) => {
            return vm.buildOneResponse(data);
          }),
          shareReplay(),
          catchError( (err: HttpErrorResponse) => {
            if (err.status === 500) {
              this.router.navigate(['/errors/something-went-wrong']);
            }
            return EMPTY;
          })
        ).toPromise();
      // let response = await this.unique().toPromise();
      this.initialSetupSubject.next(response);
      AnalyticsService.loadGoogleAnalytics(response?.data?.analytics_code);
    }
  }

  protected parseDataToObject(data) {
    return InitialSetup.instanceFromJson(data);
  }
}