import { Product } from '../product/product';
import { Translations } from '../translations/translations'

export class ProductCategory {
    public id: number;
    public position: number;
    public property_id: number;
    public restaurant_id: number;
    public use_pdf: number;
    public pdf_name: string;
    public pdf_url: string;
    public image_name: string;
    public image_url: string;
    public products: Product[];
    public translations: Translations;
    public created_at: string;
    public updated_at: string;
}
