import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AcronymService {

  constructor() { }

  parse(): string {
    const relativeAcronym = location.pathname.substring(1);
    const slashIndex = relativeAcronym.indexOf('/');
    const acronym = slashIndex !== -1 ? relativeAcronym.substring(0, slashIndex) : relativeAcronym;
    return acronym;
  }
}
