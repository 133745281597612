import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private loadingController: LoadingController,
              private translate: TranslateService
  ) {}

  async getLoadingIndicator(translateKey: string) {
    const loading = await this.loadingController.create({
      message: this.translate.instant(translateKey)
    });
    loading.present();

    return loading;
  }

}