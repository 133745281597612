import { Injectable } from '@angular/core';
import {GenericProvidersService} from '../services/generic-providers/generic-providers.service';

@Injectable()
export class GenericProvidersByCategoryProvider {
  private genericProvidersByCategory = null;

  constructor(
    private genericProvidersService: GenericProvidersService
  ) { }

  public getGenericProvidersByCategory() {
    return this.genericProvidersByCategory;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.genericProvidersService.getAll().subscribe(response => {
        this.genericProvidersByCategory = response.data;
        resolve(true);
      });
    });
  }
}
