import { RsProduct } from '../rs-product/rs-product';
import { Translations } from '../translations/translations'

export class RsProductCategory {
    public id: number;
    public property_id: number;
    public position: number;
    public image_name: string;
    public image_url: string;
    public products: RsProduct[];
    public translations: Translations;
    public created_at: string;
    public updated_at: string;
}
