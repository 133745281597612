export class Translations {
	public es: any;
	public en: any;
	public pt: any;
	public fr: any;
	public de: any;
	public ru: any;

	static instanceFromJson(data: any): Translations {
		let instance = new Translations();
		instance.es = data.es;
		instance.en = data.en;
		instance.pt = data.pt;
		instance.fr = data.fr;
		instance.de = data.de;
		instance.ru = data.ru;

		return instance;
	}
}
