import { ProviderOrder } from 'src/app/models';

export class MyOrders {
  public pending: ProviderOrder[];
  public confirmed: ProviderOrder[];

  static instanceFromJson(data: any): MyOrders{
    let instance = new MyOrders();
    instance.pending = []; //data.pending.map(order => ProviderOrder.instanceFromJson(order));
    instance.confirmed = []; //data.confirmed.map(order => ProviderOrder.instanceFromJson(order));

    return instance;
  }
}
