import { Translations } from '../translations/translations'

export class RsCatalog {
    public id: number;
    public position: number;
    public property_id: number;
    public room_service_id: number;
    public pdf_url: string;
    public image_name: string;
    public image_url: string;
    public translations: Translations
    public created_at: string;
    public updated_at: string;

    //Translations
    public name: string;

    public static basicInstance(id: number, name: string, image_url: string) {
        let instance = new this();
        instance.id = id;
        instance.name = name;
        instance.image_url = image_url;
        
        return instance;
    }
}
