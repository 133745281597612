import { OrderProduct } from '../order-product/order-product';
import { OrderProductsByOwner } from '../order-products-by-owner/order-products-by-owner';

export class Order {

    public notes: string;
	public currency: string;
	public total: number;
	public amount: number;
	public providerName: string;
    public orderProductsByOwner: OrderProductsByOwner;

    constructor(currency, providerName, products = [], notes = '') {
        this.clear();
        this.notes = notes;
        this.currency = currency;
        this.providerName = providerName;
		this.orderProductsByOwner = OrderProductsByOwner.init(products);
		this.total = this.orderProductsByOwner.order_products.reduce((a, b) => a + (b['total'] || 0), 0);
		this.amount = this.orderProductsByOwner.order_products.reduce((a, b) => a + (b['amount'] || 0), 0);
    }


	public clear() {
		this.notes = '',
		this.currency = '';
		this.total = 0;
		this.amount = 0;
	}

    public getProductAndIndex(orderProductIndex: number) {
		let curret_product;

		for (const [i, orderProduct] of this.orderProductsByOwner.order_products.entries()) {
			if (orderProduct.index == orderProductIndex) {
				curret_product = { product: orderProduct, index: i }
			}
		}

		return curret_product;
    }

	public isEmpty() {
		return this.orderProductsByOwner.order_products.length == 0;
	}

	public addProduct(newProduct: OrderProduct) {
		this.orderProductsByOwner.addProduct(newProduct);
		this.addValues(newProduct);
	}

	public removeProduct(productIndex: number) {
        const orderProduct = this.orderProductsByOwner.order_products.find(product => product.index == productIndex);
		this.orderProductsByOwner.removeProduct(orderProduct);
        this.sustractValues(orderProduct);
	}

	public updateValues(orderProduct: OrderProduct, values) {
		this.total += (orderProduct.total - values.total);
		this.amount += (orderProduct.amount - values.amount);
	}

	public addValues(orderProduct: OrderProduct) {
		this.total += orderProduct.total;
		this.amount += orderProduct.amount;
	}

	protected sustractValues(orderProduct: OrderProduct) {
		this.total -= orderProduct.total;
		this.amount -= orderProduct.amount;
	}
}