import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public weekday: string[] = [
    "SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"
  ];

  constructor() { }

  formatToBackendDateTime(date: Date): string {
  	return date.getFullYear() + '-' +
		    ('00' + (date.getMonth()+1)).slice(-2) + '-' +
		    ('00' + date.getDate()).slice(-2) + ' ' + 
		    ('00' + date.getHours()).slice(-2) + ':' + 
		    ('00' + date.getMinutes()).slice(-2) + ':' + 
		    ('00' + date.getSeconds()).slice(-2);

//    return  `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.toLocaleTimeString()}`;

  }
}
