import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { StorageService } from '../services/storage/storage.service';

@Injectable()
export class ReservationGuard implements CanActivate {

  constructor(public storage: StorageService, public router: Router) {}

  /**
   * Verificamos si el huesped tiene el token seateado mas alla de su validez.
   * Esto es asi porque en el primer request el interceptor lo appendea y se valida en el backend
   * y cuando no es valido el backend devuelve 401 y el resultado es el mismo redirect que aca. 
   * Nos ahorramos algunos request.
   */
  canActivate(): boolean {

    const token: string = this.storage.get('token', '');

    if (!token) {
        this.router.navigateByUrl('/landing/search');
        return false;
    }

    return true;
  }
}
