import { OrderState } from '../order-state/order-state';
import { ProviderType } from '../provider-type/provider-type';
import { Provider } from '../provider/provider';
import { Order } from '../order/order';
import {OrderProductsByOwner} from "../order-products-by-owner/order-products-by-owner";

export class ProviderOrder extends Order {
	//Datos desde backend
	public id: number;
	public property_id: number;
	public order_state_id: number;
	public provider_type: ProviderType;
	public order_state: OrderState;
	public updated_at: string;
	public created_at: string;
	public code: string;

	//Ambos
	public provider: Provider;
	public delivery_date: string;

	//Para manejo de frontned
	public paymentTypeId: number;
	public deliveryDate: Date;

	constructor(currency, providerName, products = [], notes = '') {
		super(currency, providerName, products, notes);
		this.clear();
	}

	static initOrder(providerId, providerName, currency, orderProducts = []) {
		let instance = new ProviderOrder(currency, providerName, orderProducts);
		instance.provider.id = providerId;
		instance.providerName = providerName;
		instance.currency = currency;

		return instance;
	}

	// Ver si esto es util, sino borrarlo
	static instanceFromJson(data: any): ProviderOrder {
		let instance = new ProviderOrder(data.currency, data.provider.name, data.products, data.notes);
		instance.id = data.id;
		instance.property_id = data.property_id;
		instance.order_state_id = data.order_state_id;
		instance.code = data.code;
		instance.order_state = OrderState.instanceFromJson(data.order_state);
		instance.updated_at = data.updated_at;
		instance.created_at = data.created_at;
		instance.provider = Provider.instanceFromJson(data.provider);
		instance.delivery_date = data.delivery_date;
		
		return instance;
  }


	static initBasicOrder(providerName, currency) {
		let instance = new ProviderOrder(currency, providerName);
		instance.providerName = providerName;
		instance.currency = currency;

		return instance;
	}


	public clear() {
		this.provider_type = new ProviderType();
		this.provider = new Provider();
		this.delivery_date = null;
	}
}