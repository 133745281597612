import { Translations } from '../translations/translations';

export class AmenityCatalog {
  public id: number;
  public image_url: string;
  public short_description: string;
  public long_description: string;
  public position: number;
  public show_title: boolean;
  public translations: Translations;
  public use_pdf: number;
  public pdf_url: string;
  public updated_at: string;
  public created_at: string;

  //Translations
  public name: string;

  public static basicInstance(id: number, name: string, image_url: string, short_description: string, long_description: string, show_title: boolean) {
    const instance = new this();
    instance.id = id;
    instance.name = name;
    instance.image_url = image_url;
    instance.short_description = short_description;
    instance.long_description = long_description;
    instance.show_title = show_title;
    return instance;
  }

}
