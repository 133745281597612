import { Translations } from '../translations/translations';

export class Activity {
    public id: number;
    public activity_category_id: number;
    public place_id: number;
    public property_id: number;
    public validity_period_id: number;
    public position: number;
    public image_name: string;
    public image_url: string;
    public translations: Translations;
    public update_at: string;
    
    //Translations
    public name: string;
    public short_description: string;
    public long_description: string;
    public days: number;
    public hour: number;

    public static basicInstance(id: number, image_url: string, name: string) {
        let instance = new this();
        instance.id = id;
        instance.image_url = image_url;
        instance.name = name;
        
        return instance;
    }

    public static majorInstance(name: string, short_description: string, image_url: string, hour: number, days: number) {
        let instance = new this();

        instance.image_url = image_url;
        instance.name = name;
        instance.short_description = short_description;
        instance.hour = hour;
        instance.days = days;
        
        return instance;
    }
}