import { Activity } from '../activity/activity';
import { Translations } from '../translations/translations';

export class ActivityCategory {
    public id: number;
    public position: number;
    public property_id: number;
    public image_name: string;
    public image_url: string;
    public translations: Translations;
    public created_at: string;
    public updated_at: string;
    public activities: Activity[];
}