import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AcronymService } from '../services/acronym/acronym.service';

@Injectable()
export class RouteRewriteRulesProvider {

  private rules = [];

  constructor(
  	protected http: HttpClient,
    private acronym: AcronymService
  ){}

  public getRules() {
    let acronym = this.acronym.parse();
    return  this.rules[acronym] ?? [];
  }

  load() {
    return new Promise((resolve, reject) => {
	    return this.http.get<any>('/assets/route-rewrite-rules/route-rewrite-rules.json').subscribe(response => {
        response.forEach(rule => {
          let rules =  this.rules.includes(rule.acronym) ? this.rules[rule.acronym] : [];
          rules.push(rule);
          this.rules[rule.acronym] = rules;
        });
        resolve(true);
      });
    });
  };

}
