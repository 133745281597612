import {Injectable} from "@angular/core";
import {BrowserQRCodeReader, IScannerControls} from "@zxing/browser";
import {LoadingService} from "../loading/loading.service";
import {ModalService} from "../modal/modal.service";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
    providedIn: 'root'
})
export class QrService {

    private codeReaderControls: IScannerControls;
    private codeReader: BrowserQRCodeReader;
    private currentDeviceIndex = 1;
    private devices = [];

    constructor(
        private loadingService: LoadingService,
        private modalService: ModalService,
        private translateService: TranslateService,
    ){
        this.codeReader = new BrowserQRCodeReader();
    }

    async initQrScanner(thenFn, catchFn, htmlElementId = 'video') {
        const loadingToken = await this.loadingService.getLoadingIndicator('GENERAL.LOADING');

        console.log("INIT QR SCANNER");

        this.getDevices()
        .then(async () => {
            const selectedDevice = this.devices[this.currentDeviceIndex] ?? this.devices[0];

            console.log("SELECTED DEVICE", selectedDevice);
            await this.codeReader.decodeFromVideoDevice(selectedDevice.deviceId, htmlElementId, async (result, error, controls) => {
                this.codeReaderControls = controls;
                await loadingToken.dismiss();
                await thenFn(result);
            });
        }).catch(error => {
            loadingToken.dismiss();
            catchFn(error);
            console.log("GET DEVICES ERROR", error);
            this.modalService.warning('ERROR.NO_PERMISSIONS_HEADER', 'ERROR.NO_PERMISSIONS_DESCRIPTION');
        });
    }

    changeCameraDevice(thenFn, catchFn, htmlElementId = 'video') {
        console.log("CHANGE CAMERA DEVICE");
        console.log("DEVICES", this.devices, "CURRENT INDEX", this.currentDeviceIndex);
        this.currentDeviceIndex += 1;
        if(!this.devices[this.currentDeviceIndex]) {
            this.currentDeviceIndex = 0;
        }
        console.log("NEW INDEX", this.currentDeviceIndex);
        this.initQrScanner(thenFn, catchFn, htmlElementId);

    }

    stopService() {
        console.log("STOP SERVICE");
        if(this.codeReaderControls) {
            this.codeReaderControls.stop();
            console.log("STOP");
        }
        this.currentDeviceIndex = 1;
    }

    private async getDevices() {
        return BrowserQRCodeReader.listVideoInputDevices().then(async devices => {
            console.log("GET DEVICES");
            console.log("DEVICES", devices);
            this.devices = this.mapDevices(devices);
            console.log("MAPPED DEVICES", this.devices);
            return devices;
        });
    }

    private mapDevices(devices: MediaDeviceInfo[]) {
        return devices.map(device => {
            const deviceObj = {};
            deviceObj['type'] = device.label.includes('front') ? 'front' : 'back';
            deviceObj['label'] = device.label.includes('front')
                ? this.translateService.instant('MYSTAY.FRONT_CAMERA')
                : device.label.includes('back')
                    ? this.translateService.instant('MYSTAY.BACK_CAMERA')
                    : device.label;
            deviceObj['deviceId'] = device.deviceId;
            return deviceObj;
        });
    }
}
