import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general/general.service';
import { ApiResponse } from 'src/app/models/api-response/api-response';
import { Observable, throwError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoyaltyProgramsService extends GeneralService<any>{

  constructor(protected http: HttpClient) { 
    super(http, 'loyalty-program');
  }

  public getConfig(): Observable<any>  {
    return this.getWithExtraUrlAndParams('/config', '');
  }

  public newAfiliate(afiliateNumber: any): Observable<any> {
    return this.post('register', afiliateNumber);
  }

  public searchAfiliate(afiliateNumber: string): Observable<any> {
    return this.post('search', {member_number: afiliateNumber});
  }
}
